import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import config from '@data/SiteConfig';
import Layout from '@components/Layout/Layout';
import SEO from '@components/SEO/SEO';

const NotFound = () => {
  return (
    <Layout hideHero>
      <SEO />
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center">
              <h1 className="display-3 font-size-48--md-down font-weight-bolder mb-3">
                Page not found
              </h1>
              <p className="lead mb-0">
                Oops! Looks like you followed a bad link.
              </p>
              <p className="lead mb-0">
                If you think this is a problem with us, please{' '}
                <a
                  className="text-secondary text-decoration-underline animated-grow-1 d-inline-block"
                  href={`mailto:${config.supportEmail}`}
                >
                  tell us
                </a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default NotFound;
